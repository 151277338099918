@import "app-bulma.sass";
@import "bulma-steps-override.scss";
@import "screens.scss";
@import "spacingHelpers.scss";

[v-cloak] { display: none }

html {
  height: 100% !important;
}

html {
  font-size: 12px;
  @include sm {
    font-size: 13px;
  }
  @include md {
    font-size: 15px;
  }
  @include lg {
    font-size: 17px;
  }
  @include xl {
    font-size: 18px;
  }
}

// Min body height is 100% to make sure background color fills browser window
body {
  min-height: 100% !important;
}

.navbar-item.is-active {
  color: $accent !important;
}

// Avoid vertical scroll from appearing with 100% body
html, body {
  padding: 0px !important;
  margin: 0px !important;
  overflow: auto !important;
}

.htmlColorBlue {
  background-color: #133652 !important;
}
.htmlColorWhite {
  background-color: white !important;
}
@mixin backgroundCover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* https://codepen.io/rasmusseidler/pen/BZdQvK */
.background-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  background-size: cover;
  &.loaded {
   opacity: 1; 
  }
}

.htmlBackgroundImage {
  background-color: #133652 !important;
  background: url(/image/waveBackground.svg) no-repeat center center fixed;
  @include backgroundCover;
}

#accountSection {
  display: none;
}

#accountSection.is-active {
  display: inline;
}

/* Make sure Chrome does not outline any divs */
:focus {
  outline: none !important;
}

/* Override Bulma's input focus border color */
textarea:focus, input:focus {
  border-color: $accent !important;
  box-shadow: inset 0 1px 2px $accent !important;
}

/* Override Bulma's input border radius */
textarea, input {
  border-radius: 2px !important;
}

.sectionPlansOpencontainer {
  width: 100%;
  position:fixed;
  bottom:5vh;
  text-align: center;
}

.sectionPlansOpencontainerChild {
  display: inline-block;
  text-align:center;
  cursor: pointer;
  color: white;
}

.sectionPlans {
  background-color: white;
}

.sectionPlansTitleContainer {
  width: 100%;
  text-align:center;
}
.sectionPlansTitle {
  @extend .indexHeading;
  display: inline-block;
  margin-top: .3ream;
  margin-bottom: 1rem;
}


.loginLogoContainer {
  width: 48px;
  height: 48px;
  display: inline-block;
  @include lg {
    width: 96px;
    height: 96px;
  }
}

.loginLogo {
  width: 48px;
  height: 48px;
  background-image: url(../image/logoColor48.png); 
  @include lg {
    width: 96px;
    height: 96px;
    background-image: url(../image/logoColor96.png); 
  }  
}

section.section {
  padding-top: 0px;
}

.loginForm label {
  color: white;
}

.is-bold {
  font-weight: bold;
}

.buttonReminderUpdate, .phoneListNew {
  cursor: pointer;
}

.flatpickrDate, .flatpickrTime {
  cursor: pointer;
}


.nowrap {
  white-space: nowrap;
}

.pageloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  left:0;
  top:0;
  bottom:0;
  right:0;
  z-index: 10000;
}

.pageloaderText {
  max-width: 50%;
  text-align: center;
}

a.has-text-white {
  text-decoration: underline;
}

.small10 {
  transform: scale(0.9, 0.9);
  -ms-transform: scale(0.9, 0.9); /* IE 9 */
  -webkit-transform: scale(0.9, 0.9); /* Safari and Chrome */
  -o-transform: scale(0.9, 0.9); /* Opera */
  -moz-transform: scale(0.9, 0.9); /* Firefox */
}

.small20 {
  transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8); /* IE 9 */
  -webkit-transform: scale(0.8, 0.8); /* Safari and Chrome */
  -o-transform: scale(0.8, 0.8); /* Opera */
  -moz-transform: scale(0.8, 0.8); /* Firefox */
}

.loginDescription {
  margin-bottom: 0em;
  padding: 1.5vw;
  color: white;
}

.loginDescriptionValueProposition1 {
  font-size: 80%;
  font-weight:600;
}

.loginDescriptionValueProposition2 {
  margin-top: 5px;
  font-size: 70%;
  font-weight:500;
}

.audioPlayback {
  cursor: pointer;
}

.audioPlayback:hover {
  -webkit-filter: brightness(125%);
  filter: brightness(125%);
}

.audioPlaybackLabel {
  font-size: 175%;
}

.sectionFooterWrapper {
  margin-top: 4rem;
  background-color:#133652;
  color:lightblue;
  font-size: 50%;
}

.sectionFooterWrapper a {
  color:lightblue;
  text-decoration: none;
}

.sectionFooter {
  width: 66vw;
  margin-left:auto;
  margin-right:auto;
}

.footerHeading {
  font-size: 110%;
  font-weight: bold;
  margin-bottom:.5rem;
}
.paywhirlLogo {
  width: 1rem;
  height: 1rem;
  vertical-align:middle;
}

.twilioLogo {
  width: auto;
  height: 2rem;
  vertical-align:middle;
}

.indexWrapper {
  left: 0px;
  right: 0px;
}

.indexContent {
  padding-top: 1rem;
  background-color:white;
  color:#133652;
}

.indexContent a {
  color:#133652;
  font-weight: bold;
}

.indexContentDark {
  @extend .indexContent;
  background-color:#133652;
  color: white;
}

.indexContentDark a {
  color:white;
  font-weight: bold;
}

.indexWave {
  z-index: -1;
  overflow:visible;
  width: 100%;
  height: auto;
  display: block;
}
.indexWaveFlipped {
  @extend .indexWave;
  transform: scale(-1,-1);
}

.indexHeading {
  font-size: 4rem;
  font-weight: 100;
}

.indexHeadingBold {
  font-size: 3.25rem;
  font-weight: 600;
}

.indexSubHeading {
  font-size: 2rem;
  font-weight: 300;
}

.indexSubHeading > super {
  font-size: 80%;
  color:#3f3f3f;
}

.headlinePhotoQuoteRight {
  display: inline-block;
  height: 4rem;
  width: auto;
}

.headlinePhotoQuoteLeft {
  display: inline-block;
  height: 4rem;
  width: auto;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.headlinePhotoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headlinePhotoInnerWrapper {
  height: 100%;
  width: 100%;
  max-width: 20vw;
}

.headlinePhoto {
  flex-grow: 1;
}

.headlinePhotoTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headlinePhotoText {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 400;
  flex-grow:1;
  text-align: center;
}

a.menuLinkTop {
  color: #B469FE;
  font-size: 1rem;
}

.pageHeading {
  font-size: 4rem;
  font-weight: 100;
}

.appleStore256 {
  width: 256px;
  height: auto;
}

.googleStore256 {
  width: 310px;
  height: auto;
}
