
body {
  background-attachment: fixed;
}

.blurred-box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
  background: inherit;
}

.blurred-box {
  background: inherit;
}

.blurred-box2, .blurred-box2-dark {
  background-color: rgba(0, 0, 0, .65);
  padding-left: 4vw;
  padding-right: 4vw;
}

.blurred-box2-white {
  background-color: rgba(255, 255, 255, .85);
  padding-left: 4vw;
  padding-right: 4vw;
}
.blurred-box2-dark {
  background-color: rgba(0, 0, 0, .65);
  padding-left: 4vw;
  padding-right: 4vw;
  color: white;
}

.blurred-box2-dark a:link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
  .blurred-box2 {
    background-color: rgba(0, 0, 0, .25);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .blurred-box2-white {
    background-color: rgba(255, 255, 255, .85);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .blurred-box2-dark {
    background-color: rgba(0, 0, 0, .65);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}

