@import "general.scss";

@import "blurred-box.scss";
@import "tour.scss";

.loginContainer {
  margin-top: 5rem;
}

a.menuLinkTop {
  color: #B469FE;
  font-size: 1rem;
}

