@import "screens.scss";


.z-overlay {
  position: fixed;
  box-sizing: content-box;
  z-index: 80;
  background-color: #000;
  opacity: .75;
  background: -moz-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,.1) 0,rgba(0,0,0,.9) 100%);
  background: -webkit-gradient(radial,center center,0,center center,100%,color-stop(0,rgba(0,0,0,.1)),color-stop(100%,rgba(0,0,0,.9)));
  background: -webkit-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,.1) 0,rgba(0,0,0,.9) 100%);
  background: -o-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,.1) 0,rgba(0,0,0,.9) 100%);
  background: -ms-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,.1) 0,rgba(0,0,0,.9) 100%);
  background: radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,.1) 0,rgba(0,0,0,.9) 100%);
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;

  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.z-highlight {
  display: default;
  box-sizing: content-box;
  position: absolute;
  z-index: 79;
  background-color: rgba(255,255,255,.8);
  border-radius: 2px;
  box-shadow: 0 2px 15px rgba(0,0,0,.4);
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.tippy-tooltip {
  background-color: #926EC8;
  color: white;
  font-size: 1rem;
  @include lg {
    font-size: 2rem;
  }
  border-radius: 2px;
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  border-bottom: 8px solid #926EC8;
}
.tippy-popper[x-placement^=top] .tippy-arrow {
  border-top: 8px solid #926EC8;
}

