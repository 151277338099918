
// https://github.com/jgthms/bulma/pull/2511
$primary: #133652 !default
$accent: #926EC8
$accent-invert: findColorInvert($accent)
$custom-colors: ("accent": ($accent, $accent-invert))

$radius-small: 1px !default
$radius: 2px !default
$radius-large: 4px !default
$navbar-item-hover-background-color: transparent !default
$navbar-item-hover-color: $accent !default
$navbar-dropdown-item-hover-color: $accent !default
$navbar-dropdown-item-hover-background-color: transparent !default
$body-font-size: 1.5rem !default

// Override non-body font sizes from initial-variables.sass
$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1.5rem !default
$size-7: 0.75rem !default

$switch-background-active: $accent

$steps-default-color: $accent
$steps-completed-color: gray
$steps-active-color: $accent

$pricing-item-background:	white
$pricing-item-border: .1rem solid WhiteSmoke

$accordion-background-color: $primary
$accordion-header-background-color: white !default
$accordion-header-color: black !default

$accordion-content-border: 0px solid !default
$accordion-content-pre-background-color: $primary !default

@import "bulma-0.7.5/bulma.sass"
@import "bulma-extensions/bulma-accordion/src/sass/index.sass"
@import "bulma-extensions/bulma-pricingtable/src/sass/index.sass"
@import "bulma-extensions/bulma-steps/src/sass/index.sass"
@import "bulma-extensions/bulma-switch/src/sass/index.sass"

.navbar-dropdown
  font-size: 1.5rem
.navbar-link:not(.is-arrowless)
  &::after
    border-color: $primary
.select
  &:not(.is-multiple):not(.is-loading)
    &::after
      border-color: $primary

.button.is-accent.is-inverted.is-hovered, .button.is-accent.is-inverted:hover
  background-color: transparent !important
  color: lighten($accent, 10%)
